import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading } from 'common/typography';
import { Block } from 'common/layout';

export const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.recklessNeue};
  font-size: 9.5vw;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.04em;
  font-weight: 100;

  ${media.desktop`
    font-size: 3.5vw;
  `}
`;

export const Container = styled(Block)`
  height: 75vh;
  background-color: ${(props) => props.theme.colors.springWood};

  ${media.desktop`
    height: 100vh;
  `}
`;
