import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading } from 'common/typography';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 30px 0 30px;

  ${media.desktop`
    padding: 253px 50px 0 50px;
  `}

  ${media.large`
    max-width: 75%;
    margin: 0 auto;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.bigShouldersDisplay};
  color: ${(props) => props.theme.colors.text};
  font-size: 50px;
  font-weight: 800;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  line-height: 100%;

  ${media.desktop`
    font-size: 96px;
  `}
`;
