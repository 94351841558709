import styled, { keyframes } from 'styled-components';

import { hexToRgba } from 'services/style';
import { media } from 'styles/utils';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const TitlePlaceholder = styled.div`
  position: relative;
  width: 145px;
  height: 50px;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '20%')};
  margin: 0 0 20px;
  overflow: hidden;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => hexToRgba(theme.colors.white, '0%')} 0,
      ${({ theme }) => hexToRgba(theme.colors.white, '30%')} 60%,
      ${({ theme }) => hexToRgba(theme.colors.white, '0%')}
    );
    animation: ${shimmer} 2s infinite;
  }

  ${media.desktop`
    width: 200px;
  `}
`;

export const PricePlaceholder = styled.div`
  position: relative;
  width: 75px;
  height: 20px;
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '10%')};
  overflow: hidden;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => hexToRgba(theme.colors.white, '0%')} 0,
      ${({ theme }) => hexToRgba(theme.colors.white, '30%')} 60%,
      ${({ theme }) => hexToRgba(theme.colors.white, '0%')}
    );
    animation: ${shimmer} 2s infinite;
  }
`;

export const TagPlaceholder = styled.div`
  height: 46px;
`;
