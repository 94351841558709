import styled, { css } from 'styled-components';

export const ExtendedBlock = styled.div<ExtendedBlockProps>`
  overflow: hidden;
  height: 90vh;

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100vh;
    `};
`;

type ExtendedBlockProps = {
  $fullHeight?: boolean;
};
