import * as i from 'types';
import * as React from 'react';
import { Trans } from 'next-i18next';

import CalendarIcon from 'vectors/internal/calendar-general.svg';
import { Tag } from 'common/layout';

export const SeriesTag: React.FC<SeriesTagProps> = ({ series }) => {
  return (
    <Tag small>
      <CalendarIcon />
      {series.expirationInterval === 'm' && (
        <Trans i18nKey="series.tag.expiration.month" count={series.expiresIn}>
          Valid for {{ count: series.expiresIn }} month
        </Trans>
      )}
      {series.expirationInterval === 'd' && (
        <Trans i18nKey="series.tag.expiration.day" count={series.expiresIn}>
          Valid for {{ count: series.expiresIn }} day
        </Trans>
      )}
    </Tag>
  );
};

type SeriesTagProps = {
  series: i.Series;
};
