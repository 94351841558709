import * as i from 'types';
import * as React from 'react';
import { useRouter } from 'next/router';

import { useIntersection, useScrollTo } from 'hooks';
import { useCreateOrder } from 'queries/orders';
import { emptyArray } from 'services/emptyArray';
import { getSeriesTypeNameById } from 'services/getSeriesTypeName';
import { StyledLink } from 'common/interaction';
import { GiftCardSeries, PricingNav, Series, SeriesSkeleton } from 'modules/pricing';

import {
  Container,
  MobileDescription,
  ScrollContainer,
  SeriesContainer,
  SeriesTypeContainer,
  SeriesTypeTitle,
  SeriesTypeTitlePlaceholder,
  StyledHeadline,
} from './styled';

// Series is the general name of a pricing item. A series can be a package or a membership.
export const PricingBlock: React.FC<PricingBlockProps> = ({ pricingTypes }) => {
  const router = useRouter();
  const hash = router.asPath.split('#')[1];
  const createOrder = useCreateOrder();
  const { scrollTo } = useScrollTo();
  const packagesRef = React.useRef<HTMLDivElement>(null);
  const isPackagesActive = useIntersection(packagesRef);
  const membershipsRef = React.useRef<HTMLDivElement>(null);
  const isMembershipsActive = useIntersection(membershipsRef);
  const giftcardsRef = React.useRef<HTMLDivElement>(null);
  const isGiftcardsActive = useIntersection(giftcardsRef);
  const hasMemberships =
    pricingTypes?.memberships && Object.keys(pricingTypes?.memberships).length > 0;

  React.useEffect(() => {
    if (pricingTypes?.packages && hash) {
      scrollTo(`#${hash}`, -5);
    }
  }, [pricingTypes?.packages]);

  const handleSelectSeries = async (item: i.Series | 'giftCard') => {
    if (createOrder.isLoading) return;

    if (item === 'giftCard') {
      router.push('/checkout/gift-card');
    } else {
      createOrder.mutate(item.id, {
        onSuccess: (order) => {
          if (order) {
            router.push(`/checkout/${order.id}/address`);
          }
        },
      });
    }
  };

  return (
    <Container>
      <SeriesTypeContainer>
        <PricingNav
          {...{ isPackagesActive, isMembershipsActive, isGiftcardsActive, hasMemberships }}
        />
        <ScrollContainer id="packages" ref={packagesRef}>
          <StyledHeadline as="h2">Packages</StyledHeadline>
          <MobileDescription>Start now with our packages. Just pay as you go.</MobileDescription>
          {pricingTypes?.packages ? (
            Object.entries(pricingTypes.packages).map(([typeId, packages]) => (
              <div key={typeId}>
                <SeriesTypeTitle>{getSeriesTypeNameById(typeId, packages)}</SeriesTypeTitle>
                <SeriesContainer>
                  {packages.map((packagesItem) => (
                    <Series
                      key={packagesItem.id}
                      item={packagesItem}
                      onClick={handleSelectSeries}
                    />
                  ))}
                </SeriesContainer>
              </div>
            ))
          ) : (
            <SeriesTypeContainer>
              <SeriesTypeTitlePlaceholder />
              <SeriesContainer>
                {emptyArray(3).map((_, index) => (
                  <SeriesSkeleton key={index} />
                ))}
              </SeriesContainer>
            </SeriesTypeContainer>
          )}
        </ScrollContainer>
        <ScrollContainer id="memberships" ref={membershipsRef}>
          {hasMemberships && (
            <>
              <StyledHeadline as="h2">Memberships</StyledHeadline>
              <MobileDescription>
                Introducing Memberships, automatically refill your classes at discounted rates.
                Credits are valid for and renew every 28 days. Cancel anytime{' '}
                <StyledLink
                  href="/stories/romemberships"
                  target="_blank"
                  $variant="reckless"
                  $isItalic
                  $withoutTextTransform
                >
                  Learn more
                </StyledLink>
              </MobileDescription>
            </>
          )}
          {pricingTypes?.memberships ? (
            Object.entries(pricingTypes.memberships).map(([typeId, memberships]) => (
              <div key={typeId}>
                <SeriesTypeTitle>{getSeriesTypeNameById(typeId, memberships)}</SeriesTypeTitle>
                <SeriesContainer>
                  {memberships.map((membership) => (
                    <Series key={membership.id} item={membership} onClick={handleSelectSeries} />
                  ))}
                </SeriesContainer>
              </div>
            ))
          ) : (
            <SeriesTypeContainer>
              <SeriesTypeTitlePlaceholder />
              <SeriesContainer>
                {emptyArray(3).map((_, index) => (
                  <SeriesSkeleton key={index} />
                ))}
              </SeriesContainer>
            </SeriesTypeContainer>
          )}
        </ScrollContainer>
        <ScrollContainer id="giftCards" ref={giftcardsRef}>
          <StyledHeadline as="h2">Gift Cards</StyledHeadline>
          <MobileDescription $margin="30px 0">
            Spread the Ro’joy and treat your Ro’buddy with a giftcard.
          </MobileDescription>
          <SeriesContainer>
            <GiftCardSeries onClick={handleSelectSeries} />
          </SeriesContainer>
        </ScrollContainer>
      </SeriesTypeContainer>
    </Container>
  );
};

type PricingBlockProps = {
  pricingTypes?: i.SeriesGroupedType;
};
