import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LargeBigShouldersText = styled.span`
  font-weight: 800;
  font-size: 25vw;
  line-height: 80%;
  letter-spacing: -0.02em;
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;

  ${media.tablet`
    font-size: 15vw;
  `}
`;

export const SmallFavoritText = styled.span<SmallFavoritTextProps>`
  font-size: 6vw;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-weight: 100;

  ${media.tablet`
    font-size: 3.5vw;
  `}

  ${media.desktop`
    font-size: 3.5vw;
  `}

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}
`;

type SmallFavoritTextProps = {
  uppercase?: boolean;
}

export const SmallRecklessText = styled.span`
  font-size: 5vw;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.recklessNeue};
  font-weight: 100;
  text-align: right;
  line-height: 100%;

  ${media.tablet`
    font-size: 3.5vw;
  `}
`;

export const PartyOnABikeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${SmallFavoritText} {
    margin-bottom: 5px;
  }

  ${media.tablet`
    ${SmallFavoritText} {
      margin-bottom: 10px;
    }
  `}

  ${media.desktop`
    ${SmallFavoritText} {
      margin-bottom: 19px;
    }
  `}
`;

export const MoveSweatStretch = styled.div`
  display: flex;
  flex-direction: column;

  ${SmallFavoritText} {
    margin-bottom: 5px;
  }

  ${media.tablet`
    ${SmallFavoritText} {
      margin-bottom: 10px;
    }
  `}

  ${media.desktop`
    ${SmallFavoritText} {
      margin-bottom: 19px;
    }
  `}
`;

export const WorldOffMusicOnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${SmallFavoritText} {
    margin-bottom: 10px;
  }

  ${media.tablet`
    ${SmallFavoritText} {
      margin-bottom: 30px;
    }
  `}
`;

export const GetFiredUpWrapper = styled.div`
  display: flex;
  align-items: center;

  ${LargeBigShouldersText} {
    margin: 0 7px;
  }

  ${media.tablet`
    ${LargeBigShouldersText} {
      margin: 0 19px;
    }
  `}
`;
