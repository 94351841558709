import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const PricingAbsoluteContainer = styled.div`
  position: sticky;
  top: 50px;
  height: 50px;
  z-index: 1;

  ${media.desktop`
    position: absolute;
    left: 0;
    top: auto;
    height: 100%;
    margin-top: 120px;
  `}
`;

export const PricingNavContainer = styled.div`
  position: sticky;
  top: 100px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${media.desktop`
    height: auto;
    display: block;
  `}
`;

export const LinkItem = styled.div<LinkItemProps>`
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-bottom: 1px solid ${theme.colors.black};
      color: ${theme.colors.black};
      font-weight: 500;
    `}

  ${media.desktop<LinkItemProps>`
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 10vw;
    margin-left: 50px;
    border-left: 2px solid ${({ theme }) => theme.colors.gray.light};
    padding: 16px;

    ${({ isActive, theme }) =>
      isActive &&
      css`
        border-left-color: ${theme.colors.black};
        border-bottom: 0;
        color: ${theme.colors.black};
        font-weight: 500;
      `}
  `}
`;

type LinkItemProps = {
  isActive?: boolean;
};

export const UppercaseLink = styled.a`
  text-transform: uppercase;
`;

export const Description = styled(Text).attrs({
  $variant: 'reckless',
})`
  display: none;

  ${media.desktop`
    display: block;
  `}
`;
