import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useSize } from 'services/hooks';
import ArrowRightIcon from 'vectors/internal/arrow-right.svg';
import { Block, BlurredCirclesBackground } from 'common/layout';

import {
  TextWrapper,
  NotFoundWrapper,
  NotFoundSmallText,
  NotFoundMainText,
  NotFoundTextLink,
} from './styled';

export const NotFoundBlurredCirclesBlock: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);
  const { t } = useTranslation();

  return (
    <Block overflow="hidden" ref={target}>
      <BlurredCirclesBackground width={width} height={height}>
        <TextWrapper>
          <NotFoundWrapper>
            <NotFoundSmallText>404</NotFoundSmallText>
            <NotFoundMainText>{t('Oops, your page was not found')}</NotFoundMainText>
            <NotFoundTextLink href="/">
              {t('Go to homepage')}
              <ArrowRightIcon width={24} height={24} />
            </NotFoundTextLink>
          </NotFoundWrapper>
        </TextWrapper>
        {children}
      </BlurredCirclesBackground>
    </Block>
  );
};
