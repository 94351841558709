import React from 'react';
import { useTranslation } from 'next-i18next';

import { TextureTile } from 'common/layout';

import { Price } from '../Series/styled';

export const GiftCardSeries: React.FC<GiftCardSeriesProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <TextureTile.Container onClick={() => onClick('giftCard')} includeNoisePattern>
      <TextureTile.Headline $hyphens>{t('Gift card')}</TextureTile.Headline>
      <Price>€ 20-500</Price>
      <TextureTile.CallToAction>
        {t('Buy gift card')} {'->'}
      </TextureTile.CallToAction>
    </TextureTile.Container>
  );
};

type GiftCardSeriesProps = {
  onClick: (id: 'giftCard') => void;
};
