import styled from 'styled-components';

import { media } from 'styles/utils';
import { LinkStyles } from 'common/interaction';
import { FocalPointImage } from 'common/layout';
import { Text } from 'common/typography';

export const ExtendedBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20vw 0;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${media.tablet`
    margin: 10vw 0 0;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;

export const ExtendedText = styled(Text)`
  position: absolute;
  z-index: 1;
  line-height: 115%;
  letter-spacing: -0.03em;
  font-size: 24px;
  text-align: center;
  padding: 0 20vw;
  font-feature-settings: 'ss04' on, 'calt' off;

  span {
    text-decoration: underline;
  }

  ${media.tablet`
    font-size: 48px;
    letter-spacing: -0.04em;
    line-height: 100%;
  `}
`;

export const HeaderImage = styled(FocalPointImage)`
  pointer-events: none;
`;

export const ChangeLocation = styled.u`
  cursor: pointer;
`;

export const EditLocationButton = styled.button`
  ${LinkStyles}
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  svg {
    width: 16px;
    margin-right: 6px;
  }
`;
