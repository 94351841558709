import * as React from 'react';

import { ProgressBar, ProgressContainer } from './styled';

const VideoProgressBar: React.FC<Props> = (props) => {
  const [percentagePlayed, setPercentagePlayed] = React.useState(0);

  function onTimeUpdate(this: HTMLVideoElement) {
    const percentage = (this.currentTime / this.duration) * 100;
    setPercentagePlayed(percentage);
  }

  React.useEffect(() => {
    if (props.videoRef.current) {
      props.videoRef.current.addEventListener('timeupdate', onTimeUpdate);
    }

    return () => {
      if (props.videoRef.current) {
        props.videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
      }
    };
  }, [props.videoRef]);

  return (
    <ProgressContainer>
      <ProgressBar percentagePlayed={percentagePlayed} />
    </ProgressContainer>
  );
};

export type Props = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

export default VideoProgressBar;
