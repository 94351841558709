import * as React from 'react';
import { Trans } from 'next-i18next';

import { useLocationSidebar } from 'services/hooks';
import PencilIcon from 'vectors/internal/pencil.svg';
import { Headline2, Text } from 'common/typography';

import { ContentContainer, EditLocationButton, ExtendedBlock } from './styled';

export const PricingHeader: React.FC<PricingHeaderProps> = ({ location }) => {
  const { toggleLocationSidebar } = useLocationSidebar();

  return (
    <ExtendedBlock>
      <ContentContainer>
        <Headline2 size="xl" variant="bigShoulders">
          <Trans i18nKey="pricing.header.title" values={{ location }}>
            Our pricing in{' '}
          </Trans>
        </Headline2>
        <Text $margin="16px">
          <Trans i18nKey="pricing.subtitle">
            You can also make purchases at our studios using any bank or credit card. Please note
            that we do not accept cash.
          </Trans>
        </Text>
        <EditLocationButton onClick={toggleLocationSidebar}>
          <PencilIcon />
          <Trans i18nKey="pricing.location">Change location</Trans>
        </EditLocationButton>
      </ContentContainer>
    </ExtendedBlock>
  );
};

type PricingHeaderProps = {
  location: string;
};
