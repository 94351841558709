import styled from 'styled-components';

export const Price = styled.p`
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  font-feature-settings: 'ss04' on;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;
