import * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { Asset } from 'contentful';

import { isServer } from 'services';
import { usePageVisibility } from 'services/hooks';
import { Video } from 'common/interaction';

import VideoProgressBar from './VideoProgressBar';
import VideoProgressTime from './VideoProgressTime';
import { AudioToggle, ExtendedBlock, VideoBlockLogoIcon, LogoContainer } from './styled';

export const VideoBlock: React.FC<VideoBlockProps> = ({ source, shouldAnimate, videoVisible }) => {
  const { t } = useTranslation();
  const player = React.useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = React.useState(true);
  const [shouldPlay, setShouldPlay] = React.useState(false);
  const [isFullScreen, setisFullScreen] = React.useState(true);
  const [isMounted, setIsMounted] = React.useState(false);
  const isVisible = usePageVisibility();

  React.useEffect(() => {
    if (isServer) return;
    setIsMounted(true);
  }, []);

  const onToggleMute = () => {
    setIsMuted(!isMuted);
  };

  const blockAnimations = shouldAnimate
    ? {
        initial: { scale: '0.8', height: '100vh' },
        animate: { scale: 1, height: '93.5vh' },
        transition: { delay: 1.5, duration: 0.2 },
      }
    : {};
  const logoAnimations = shouldAnimate
    ? { initial: { scale: 2 }, animate: { scale: 1 }, transition: { delay: 1.5, duration: 0.2 } }
    : {};

  // Delay playing the video when the intro is animated
  React.useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setShouldPlay(true);
      }, 1500);
    } else {
      setShouldPlay(true);
    }

    // return () => clearTimeout();
  }, [shouldAnimate]);

  React.useEffect(() => {
    if (player?.current) {
      if (isVisible && shouldPlay && videoVisible) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        player.current.play().catch(() => {});
      } else {
        player.current.pause();
      }
    }
  }, [isVisible, player, shouldPlay, videoVisible]);

  return (
    <ExtendedBlock
      initial={blockAnimations.initial}
      animate={blockAnimations.animate}
      transition={blockAnimations.transition}
      $isFullScreen={isFullScreen}
      onAnimationComplete={() => setisFullScreen(false)}
    >
      <Video loop playsInline muted={isMuted} ref={player}>
        <source src={source?.fields.file.url} type="video/mp4" />
      </Video>
      <LogoContainer
        initial={logoAnimations.initial}
        animate={logoAnimations.animate}
        transition={logoAnimations.transition}
      >
        <VideoBlockLogoIcon />
      </LogoContainer>
      <AudioToggle onClick={onToggleMute}>{isMuted ? t('Audio off') : t('Audio on')}</AudioToggle>
      {isMounted && <VideoProgressTime videoRef={player} />}
      {isMounted && <VideoProgressBar videoRef={player} />}
    </ExtendedBlock>
  );
};

type VideoBlockProps = {
  source?: Asset;
  shouldAnimate: boolean;
  videoVisible: boolean;
};
