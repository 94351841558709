import type * as i from 'types';
import * as React from 'react';

import { TitleWrapper, ExtendedHeading } from './styled';

export const ContentTitleBlock: React.FC<ContentTitleBlockProps> = ({ title, author }) => {
  return (
    <TitleWrapper>
      <ExtendedHeading>{title}</ExtendedHeading>
    </TitleWrapper>
  );
};

type ContentTitleBlockProps = {
  title: string;
  author?: i.TypeAuthorFields;
};
