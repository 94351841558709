import * as React from 'react';

import { ProgressTime } from './styled';

const VideoProgressTime: React.FC<Props> = (props) => {
  const [currentTime, setCurrentTime] = React.useState('0:00');

  function onTimeUpdate(this: HTMLVideoElement) {
    const time = new Date(this.currentTime * 1000).toISOString().slice(15, 19);
    setCurrentTime(time);
  }

  React.useEffect(() => {
    if (props.videoRef.current) {
      props.videoRef.current.addEventListener('timeupdate', onTimeUpdate);
    }

    return () => {
      if (props.videoRef.current) {
        props.videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
      }
    };
  }, [props.videoRef]);

  return <ProgressTime>{currentTime}</ProgressTime>;
};

export type Props = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

export default VideoProgressTime;
