import * as React from 'react';

import { useSize } from 'services/hooks';
import { getRandomInt } from 'services/mathHelpers';
import { Block, BlurredCirclesBackground } from 'common/layout';

import { getTextVariant } from './getTextVariant';
import { TextWrapper } from './styled';

export const BlurredCirclesBlock: React.FC<Props> = ({ children }) => {
  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);
  const [variant, setVariant] = React.useState<number>(0);

  // Only set the variant when the client is loaded, see:
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  React.useEffect(() => {
    setVariant(getRandomInt(0, 3));
  }, []);

  return (
    <Block overflow="hidden" ref={target}>
      <BlurredCirclesBackground width={width} height={height}>
        <TextWrapper>{getTextVariant(variant)}</TextWrapper>
        {children}
      </BlurredCirclesBackground>
    </Block>
  );
};

type Props = {
  children: React.ReactNode;
};
