import styled from 'styled-components';

import { media } from 'styles/utils';
import { Headline2, Text } from 'common/typography';

export const Container = styled.div`
  padding: 0 20px;
  position: relative;
`;

export const StyledHeadline = styled(Headline2)`
  text-align: center;
  margin: 30px 0;
`;

export const SeriesTypeContainer = styled.div`
  width: 100%;
  max-width: 459px;
  margin: 0 auto;

  &:last-of-type {
    margin-bottom: 50px;
  }

  ${media.desktop`
    max-width: 930px;
    text-align: center;
  `}
`;

export const SeriesTypeTitle = styled.p`
  text-align: center;
  margin: 30px 0;
`;

export const SeriesTypeTitlePlaceholder = styled.div`
  width: 150px;
  height: 19px;
  margin: 30px auto;
`;

export const SeriesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px 0;

  ${media.desktop`
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 56px 12px;
  `}
`;

export const ScrollContainer = styled.div`
  padding-top: 100px;

  ${media.desktop`
    padding-top: 60px;
  `}
`;

export const MobileDescription = styled(Text).attrs({
  $variant: 'reckless',
})`
  text-align: center;

  ${media.desktop`
    display: none;
  `}
`;
