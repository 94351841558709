import * as React from 'react';

import { useSize } from 'services/hooks';
import { BlurredCirclesBackground } from 'common/layout';

import { TextWrapper, ExtendedHeading, Container } from './styled';

export const PageBlurredCirclesBlock: React.FC<PageBlurredCirclesBlockProps> = ({ title }) => {
  const target = React.useRef<HTMLDivElement | null>(null);
  const [width, height] = useSize(target);

  return (
    <Container overflow="hidden" ref={target}>
      <BlurredCirclesBackground {...{ width, height }}>
        <TextWrapper>
          <ExtendedHeading>{title}</ExtendedHeading>
        </TextWrapper>
      </BlurredCirclesBackground>
    </Container>
  );
};

type PageBlurredCirclesBlockProps = {
  title?: string;
};
