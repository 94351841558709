import * as React from 'react';

import {
  PartyOnABikeWrapper,
  SmallFavoritText,
  LargeBigShouldersText,
  SmallRecklessText,
  WorldOffMusicOnWrapper,
  GetFiredUpWrapper,
  MoveSweatStretch,
} from './styled';

export const getTextVariant = (variant: number) => {
  switch (variant) {
    default:
    case 0:
      return (
        <PartyOnABikeWrapper>
          <SmallFavoritText>
            The
          </SmallFavoritText>
          <LargeBigShouldersText>
            Party
          </LargeBigShouldersText>
          <SmallRecklessText>
            on a bike
          </SmallRecklessText>
        </PartyOnABikeWrapper>
      );
    case 1:
      return (
        <GetFiredUpWrapper>
          <SmallFavoritText uppercase>
            Get
          </SmallFavoritText>
          <LargeBigShouldersText>
            Fired
          </LargeBigShouldersText>
          <SmallFavoritText uppercase>
            Up
          </SmallFavoritText>
        </GetFiredUpWrapper>
      );
    case 2:
      return (
        <WorldOffMusicOnWrapper>
          <SmallFavoritText uppercase>
            World off
          </SmallFavoritText>
          <LargeBigShouldersText>
            Music on
          </LargeBigShouldersText>
        </WorldOffMusicOnWrapper>
      );
    case 3:
      return (
        <MoveSweatStretch>
          <SmallFavoritText>
            Move
          </SmallFavoritText>
          <LargeBigShouldersText>
            Sweat
          </LargeBigShouldersText>
          <SmallRecklessText>
            & Stretch
          </SmallRecklessText>
        </MoveSweatStretch>
      );
  }
};
