import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';

export const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;

  ${media.desktop`
    max-width: 50%;
    margin-bottom: 0px;
  `}
`;

export const NotFoundSmallText = styled.span`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  text-align: center;
  line-height: 130%;
  font-size: 6.5vw;

  ${media.desktop`
    font-size: 1.5vw;
  `}
`;

export const NotFoundMainText = styled.span`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  line-height: 100%;
  letter-spacing: -0.03em;
  font-size: 8.5vw;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const NotFoundTextLink = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: fit-content;
  align-items: center;
  margin-top: 50px;
  font-size: 3.5vw;

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    font-size: 0.75vw;
  `}
`;
