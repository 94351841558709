import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Entry } from 'contentful';

import { Thumbnail } from 'common/general';

import {
  ListWrapper,
  SimilarStoriesWrapper,
  ExtendedText,
  SimilarStoriesList,
  Container,
  TitleContainer,
  Title,
  ArrowContainer,
  ArrowIcon,
  ImageContainer,
  LinkWrapper,
} from './styled';

export const ContentRelatedPagesBlock: React.FC<ContentRelatedPagesBlockProps> = ({
  relatedPages,
  relatedTitle,
}) => {
  const { t } = useTranslation();

  return (
    <SimilarStoriesWrapper>
      <ListWrapper>
        <ExtendedText>{relatedTitle || t('Next chapter')}</ExtendedText>
        <SimilarStoriesList>
          {relatedPages.map((page) => (
            <LinkWrapper key={page.sys.id} href={page.fields.slug}>
              <Container>
                <ImageContainer>
                  <Thumbnail image={page.fields.headerImage.fields} sizes="100px" />
                </ImageContainer>
                <TitleContainer>
                  <Title>{page.fields.title}</Title>
                </TitleContainer>
                <ArrowContainer>
                  <ArrowIcon />
                </ArrowContainer>
              </Container>
            </LinkWrapper>
          ))}
        </SimilarStoriesList>
      </ListWrapper>
    </SimilarStoriesWrapper>
  );
};

type ContentRelatedPagesBlockProps = {
  relatedPages: Entry<Record<string, any>>[];
  relatedTitle: string | undefined;
};
