import * as React from 'react';

import { useScrollTo } from 'services/hooks';
import { StyledLink } from 'common/interaction';

import {
  Description,
  LinkItem,
  PricingAbsoluteContainer,
  PricingNavContainer,
  UppercaseLink,
} from './styled';

export const PricingNav: React.FC<PricingNavProps> = ({
  isPackagesActive,
  isMembershipsActive,
  isGiftcardsActive,
  hasMemberships,
}) => {
  const { scrollTo } = useScrollTo();
  const [isActive, setIsActive] = React.useState('');

  const scrollToSection = (e, hash) => {
    e.preventDefault();
    scrollTo(`#${hash}`, -5, true);
  };

  React.useEffect(() => {
    if (isPackagesActive) {
      setIsActive('packages');
    } else if (isMembershipsActive) {
      setIsActive('memberships');
    } else if (isGiftcardsActive) {
      setIsActive('giftCards');
    }
  }, [isPackagesActive, isMembershipsActive, isGiftcardsActive]);

  return (
    <PricingAbsoluteContainer>
      <PricingNavContainer>
        <LinkItem
          onClick={(e) => scrollToSection(e, 'packages')}
          isActive={isActive === 'packages'}
        >
          <UppercaseLink>Packages</UppercaseLink>
          {isActive === 'packages' && (
            <Description>Start now with our packages. Just pay as you go.</Description>
          )}
        </LinkItem>
        {hasMemberships && (
          <LinkItem
            onClick={(e) => scrollToSection(e, 'memberships')}
            isActive={isActive === 'memberships'}
          >
            <UppercaseLink>Memberships</UppercaseLink>
            {isActive === 'memberships' && (
              <Description>
                Introducing Memberships, automatically refill your classes at discounted rates.
                Credits are valid for and renew every 28 days. Cancel anytime.{' '}
                <StyledLink
                  href="/stories/romemberships"
                  target="_blank"
                  $variant="reckless"
                  $isItalic
                  $withoutTextTransform
                  $underline
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn more
                </StyledLink>
              </Description>
            )}
          </LinkItem>
        )}
        <LinkItem
          onClick={(e) => scrollToSection(e, 'giftCards')}
          isActive={isActive === 'giftCards'}
        >
          <UppercaseLink>Gift Cards</UppercaseLink>
          {isActive === 'giftCards' && (
            <Description>Spread the Ro’joy and treat your Ro’buddy with a giftcard.</Description>
          )}
        </LinkItem>
      </PricingNavContainer>
    </PricingAbsoluteContainer>
  );
};

type PricingNavProps = {
  isPackagesActive?: boolean;
  isMembershipsActive?: boolean;
  isGiftcardsActive?: boolean;
  hasMemberships?: boolean;
};
