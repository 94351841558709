import * as i from 'types';
import * as React from 'react';

import { FocalPointImage } from 'common/layout';

import { ExtendedBlock } from './styled';

export const ImageBlock: React.FC<ImageBlockProps> = ({
  image,
  fullHeight = true,
  style,
  loading,
}) => {
  return (
    <ExtendedBlock $fullHeight={fullHeight} style={style}>
      <FocalPointImage image={image} loading={loading} />
    </ExtendedBlock>
  );
};

type ImageBlockProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  image?: i.TypeImageWithFocalPointFields;
  fullHeight?: boolean;
  style?: Record<string, string | number>;
};
