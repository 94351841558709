import React from 'react';

import { TextureTile } from 'common/layout';

import { TitlePlaceholder, PricePlaceholder, TagPlaceholder } from './styled';

export const SeriesSkeleton: React.FC = () => {
  return (
    <TextureTile.Container tag={<TagPlaceholder />}>
      <TitlePlaceholder />
      <PricePlaceholder />
    </TextureTile.Container>
  );
};
