import * as i from 'types';
import React from 'react';
import { useTranslation } from 'next-i18next';

import { formatPrice } from 'services/formatPrice';
import { TextureTile } from 'common/layout';

import { SeriesTag } from '../SeriesTag';
import { Price } from './styled';

export const Series: React.FC<SeriesProps> = ({ item, onClick }) => {
  const { t } = useTranslation();

  return (
    <TextureTile.Container
      onClick={() => onClick(item)}
      includeNoisePattern
      tag={<SeriesTag series={item} />}
    >
      <TextureTile.Headline $hyphens>{item.name}</TextureTile.Headline>
      <Price>{formatPrice(item.groupPrice || item.price)}</Price>
      <TextureTile.CallToAction>
        {t('Buy credits')} {'->'}
      </TextureTile.CallToAction>
    </TextureTile.Container>
  );
};

type SeriesProps = {
  item: i.Series;
  onClick: (item: i.Series) => void;
};
