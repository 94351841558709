// import { Link as GatsbyLink } from 'gatsby';
import Link from 'next/link';
import styled from 'styled-components';

import { media } from 'styles/utils';
import ArrowRight from 'vectors/internal/arrow-right.svg';
import { Tag } from 'common/layout';
import { Text } from 'common/typography';

export const ExtendedTag = styled(Tag)`
  width: fit-content;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowIcon = styled(ArrowRight)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  align-self: center;
  display: block;
`;

export const ArrowContainer = styled.div`
  ${media.desktop`
    justify-self: end;
  `}
`;

// export const LinkWrapper = styled(GatsbyLink)`
export const LinkWrapper = styled(Link)`
  text-decoration: none;
  text-transform: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.favoritPro};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 25% auto 15%;
  align-items: center;
  justify-items: start;
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;

  ${media.desktop`
    grid-template-columns: 12.5% auto 15%;
    align-items: center;
    justify-items: start;
  `}

  &:hover {
    cursor: pointer;
    --d: 100%;
    --p: 100%;
  }

  & > ${TitleContainer} > ${Title} {
    background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0) 1px no-repeat;
    transition: 0.3s, background-position 0s 0.3s;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 45px;
  padding-top: 45px;

  ${media.desktop`
    gap: 42px;
    padding-top: 42px;
  `}
`;

export const ExtendedText = styled(Text)`
  font-size: 24px;
  align-self: flex-start;
  font-family: ${(props) => props.theme.fonts.favoritPro};
`;

export const SimilarStoriesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 45px;
  padding-top: 45px;

  ${media.desktop`
    gap: 42px;
    padding-top: 42px;
  `}
`;

export const SimilarStoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 150px;
  max-width: 75%;

  ${media.desktop`
    margin-top: 150px;
  `}
`;
